import cn from "classnames"
import React, { ButtonHTMLAttributes, JSXElementConstructor, forwardRef, useRef } from "react"
import { mergeRefs } from "react-merge-refs"

import LoadingSpinner from "./LoadingSpinner.tsx"

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  href?: string
  className?: any
  variant: "primary" | "secondary" | "destructive"
  arrow?: boolean
  active?: boolean
  type?: "submit" | "reset" | "button"
  Component?: string | JSXElementConstructor<any>
  width?: string | number
  loading?: boolean
  disabled?: boolean
  nofollow?: boolean
  dark?: boolean
}

const Button: React.FC<Props> = forwardRef((props: Props, buttonRef) => {
  const { className, variant = "primary", children, active, width, loading = false, disabled = false, style = {}, Component = "button", nofollow, ...rest } = props
  const ref = useRef<typeof Component>(null)

  const baseClass = "flex relative justify-center text-sm items-center tracking-wider cursor-pointer focus:outline-none rounded"

  const primaryClass = "text-brand-light bg-brand-dark border border-transparent shadow py-3 px-8 focus:outline-none"
  const secondaryClass = "border-dark-grey shadow-sm hover:shadow border-opacity-20 text-primary text-brand-dark bg-white border rounded px-8 py-3 focus:outline-none text-xs sm:text-sm"
  // const destructiveClass = "rounded border-red-300 text-white bg-red-500 hover:bg-red-600 ";
  const destructiveClass = "shadow-sm hover:shadow border-opacity-20 text-primary px-8 py-3 rounded border-red-300 text-white bg-red-500 hover:bg-red-600 "
  const disabledClass = "!opacity-25 cursor-default focus:outline-none"

  const rootClassName = cn(
    baseClass,
    loading && "border-opacity-50",
    {
      [primaryClass]: variant === "primary",
      [secondaryClass]: variant === "secondary",
      [destructiveClass]: variant === "destructive",
      [disabledClass]: disabled,
    },
    className,
  )

  const sholdBeDark = variant === "secondary"

  return (
    <Component
      className={rootClassName}
      aria-pressed={active}
      data-variant={variant}
      ref={mergeRefs([ref, buttonRef])}
      disabled={loading || disabled}
      rel={nofollow ? "nofollow noopener" : ""}
      style={{
        width,
        ...style,
      }}
      {...rest}
    >
      {loading ? <></> : children}
      {loading && (
        <div className=" ml-4">
          <LoadingSpinner dark={sholdBeDark} />
        </div>
      )}
    </Component>
  )
})

export default Button
