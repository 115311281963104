// @typescript-eslint/ban-ts-comment
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline"
import { env } from "env/client.mjs"
import { useRouter } from "next/router"
import React, { useEffect, useState } from "react"
import AsyncSelect from "react-select/async"
import { useGoogleMapsApi } from "utils/hooks/useGoogleMaps"

import Label from "./Label"

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: "100%",
  }),
  control: (provided: any) => ({
    ...provided,
    height: 42,
    borderRadius: "4px",
    fontWeight: "400",
    color: "black",
    zIndex: 10,
    borderColor: "#4b5563",
    borderOpacity: "0.75",
    fontSize: "1rem",
    "&:focus": {
      boxShadow: "none !important",
      borderColor: "!important #4b5563",
    },
    "&:hover": {
      boxShadow: "none !important",
      borderColor: "!important #4b5563",
    },
  }),
  option: (provided: any) => ({
    ...provided,
    fontSize: 14,
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  indicatorsContainer: () => ({
    display: "none",
  }),
}

const searchCities = async (
  inputValue: string,
  google: any,
  type: "address" | "cities" | "regions" | "establishment",
) => {
  if (!google) return []

  const service = new google.maps.places.AutocompleteService()

  try {
    const results = await service.getPlacePredictions({
      input: inputValue,
      types: type === "address" ? [] : [`(${type})`],
    })

    return results.predictions.map((result: any) => ({
      value: result.place_id,
      label: result.description,
    }))
  } catch (error) {
    console.log(error)
  }
}

const getAddress = async (select: SelectObj, google: any) => {
  const geoCoder = new google.maps.Geocoder()

  const { results } = await geoCoder.geocode({ placeId: select.value })

  return results[0]
}

interface GooglePlacesSearchProps {
  name: string
  label: string
  placeholder?: string
  isMulti: boolean
  type: "address" | "cities" | "regions" | "establishment"
  onChange: (v: any) => void
  value?: any
}

export type SelectObj = {
  value: string
  label: string
}

export default function GooglePlacesSearch(props: GooglePlacesSearchProps) {
  const { name, label, isMulti = true, type, onChange, value, placeholder } = props
  const [selected, setSelected] = useState<SelectObj | null>(null)
  const router = useRouter()
  const lang = router.locale || "en"
  const googleMaps = useGoogleMapsApi(env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY, lang)
  const noOptionsText = lang === "de" ? "Keine Optionen" : "No options"

  useEffect(() => {
    setSelected({ label: value, value: value })
  }, [value])

  return (
    <>
      {label && <Label htmlFor={name} text={label} />}
      <div className="relative">
        <AsyncSelect
          styles={customStyles}
          isMulti={isMulti}
          noOptionsMessage={() => noOptionsText}
          openMenuOnClick={false}
          onChange={async (selected: SelectObj | SelectObj[]) => {
            if (Array.isArray(selected)) {
              onChange(selected.map(({ value, label }: any) => ({ placesId: value, name: label })))
            } else {
              if (type === "address") {
                const address = await getAddress(selected, googleMaps)
                setSelected({ label: address.formatted_address, value: address.place_id })
                onChange(address)
              } else {
                setSelected(selected)
                onChange(selected)
              }
            }
          }}
          //Below is a hack to get the async select to work with the google maps api
          value={
            isMulti
              ? value?.[name]?.map((f: any) => ({ value: f.placesId, label: f.name })) || []
              : selected?.value
              ? selected
              : null
          }
          loadOptions={(val: string) => searchCities(val, googleMaps, type)}
          placeholder={placeholder}
        />
        <MagnifyingGlassCircleIcon className="absolute top-2 right-2 z-50 text-gray-800 opacity-75 h-6 w-6" />
      </div>
    </>
  )
}
