import { Menu, Transition } from "@headlessui/react"
import { ArrowLeftOnRectangleIcon, Bars3Icon, GlobeEuropeAfricaIcon, KeyIcon, UserCircleIcon } from "@heroicons/react/24/outline"
import cn from "classnames"
import { useAuthUser } from "next-firebase-auth"
import { useTranslation } from "next-i18next"
import { useRouter } from "next/router"
import React, { Fragment } from "react"
import useStore from "store"

import { requestPasswordReset } from "lib/firebase/firebaseClient"

import { useLanguageSwitcher } from "hooks/useLangSwitcher"

import MyneLogo from "./ui/MyneLogo"

export function LoggedInUser({ dark, admin }: { dark?: boolean; admin?: boolean }) {
  const router = useRouter()
  const user = useStore((store) => store.authUser)
  const setUser = useStore((store) => store.setAuthUser)
  const authUser = useAuthUser()
  const { t } = useTranslation("common")
  const { currentLanguage, toggleLanguage } = useLanguageSwitcher()

  const onSignOutClick = () => {
    authUser.signOut()
    setUser(null)
    router.push("/")
  }

  const onChangePasswordClick = React.useCallback(() => {
    if (!!user?.email && confirm(t("loginflow.password_reset_modal.subTitle"))) {
      requestPasswordReset(user.email)
    }
  }, [user, t])

  return (
    <Menu as="div" className="ml-3 relative flex-shrink-0 pt-2">
      <div>
        <Menu.Button
          className={cn("rounded-full bg-light-grey opacity-80 hover:opacity-100 w-[60px] lg:w-[64px] h-8 lg:h-10 relative py-1 flex items-center shadow text-base", {
            "text-primary border-primary": dark,
            "text-white border-white": !dark,
          })}
        >
          <span className="sr-only">Open user menu</span>
          {user ? <UserCircleIcon className="h-6 w-6 absolute left-1.5 text-brand-dark" /> : <GlobeEuropeAfricaIcon className="h-6 w-6 absolute left-1.5 text-brand-dark" />}
          <Bars3Icon className="w-3 h-3 absolute right-3 text-brand-dark" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute top-10 lg:top-12 right-4 w-[200px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          {user && (
            <Menu.Item>
              <div className="text-sm text-primary flex flex-col p-4 font-bold tracking-wider border-light-grey border-b rounded-md  text-ellipsis whitespace-nowrap overflow-hidden">
                {user?.profile?.firstName} {user?.profile?.lastName}
                <span className="text-xs text-ellipsis whitespace-nowrap overflow-hidden">{user?.email}</span>
              </div>
            </Menu.Item>
          )}
          {!admin && (
            <>
              <Menu.Item>
                <div className="text-sm text-primary flex items-center px-4 py-3 font-bold tracking-wider cursor-pointer hover:bg-light-grey" onClick={toggleLanguage}>
                  <span className="mr-1">{currentLanguage === "en" ? "🇩🇪" : "🇬🇧"}</span> {t("navbar.toggleLanguageLabel")}
                </div>
              </Menu.Item>
              <Menu.Item>
                <div className="flex items-center text-sm text-primary px-4 py-3 font-bold tracking-wider cursor-pointer hover:bg-light-grey rounded-md" onClick={onChangePasswordClick}>
                  <KeyIcon className="w-4 h-4 mr-1" /> {t("navbar.change_password")}
                </div>
              </Menu.Item>
            </>
          )}
          {user && (
            <Menu.Item>
              <div className="flex items-center text-sm text-primary px-4 py-3 font-bold tracking-wider cursor-pointer hover:bg-light-grey rounded-md" onClick={onSignOutClick}>
                <ArrowLeftOnRectangleIcon className="w-4 h-4 mr-1" /> {admin ? "Log out" : t("loginflow.logout")}
              </div>
            </Menu.Item>
          )}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

const Navbar = () => {
  return (
    <nav className=" w-full absolute h-[80px]">
      <div className="w-full relative flex justify-center items-center pb-6 pt-9  max-w-xl mx-auto">
        <MyneLogo className="h-full w-[100px] sm:w-[120px] mx-auto" />

        <div className="absolute right-4 top-5 z-50">
          <LoggedInUser />
        </div>
      </div>
    </nav>
  )
}

export default Navbar
