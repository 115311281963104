// @ts-check
import { z } from "zod"

/**
 * Specify your server-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 */
export const serverSchema = z.object({
  DATABASE_URL: z.string().url(),
  NODE_ENV: z.enum(["development", "test", "production"]),
  DOCUSIGN_INTEGRATION_KEY: z.string(),
  DOCUSIGN_USER_ID: z.string(),
  DOCUSIGN_BASE_URL: z.string(),
  DOCUSIGN_JWT: z.string(),
  DOCUSIGN_PRIVATE_KEY: z.string(),
  DOCUSIGN_REGULATORY_TEMPLATE_ID: z.string(),
  DOCUSIGN_REDIRECT_URL: z.string(),
  SLACK_AUTH_TOKEN: z.string(),
  SLACK_ONBOARDING_CHANNEL_ID: z.string(),
  FIREBASE_ADMIN_PROJECT_ID: z.string(),
  FIREBASE_ADMIN_PRIVATE_KEY_ID: z.string(),
  FIREBASE_ADMIN_PRIVATE_KEY: z.string(),
  FIREBASE_ADMIN_CLIENT_EMAIL: z.string(),
  FIREBASE_ADMIN_CLIENT_ID: z.string(),
  FIREBASE_CLIENT_CERT_URL: z.string(),
  COOKIE_SECRET_CURRENT: z.string(),
  COOKIE_SECRET_PREVIOUS: z.string(),
  FIREBASE_AUTH_EMULATOR_HOST: z.string().optional(),
  CUSTOMERIO_API_KEY: z.string(),
})

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
export const clientSchema = z.object({
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: z.string(),
  NEXT_PUBLIC_FIREBASE_API_KEY: z.string(),
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: z.string(),
})

/**
 * @type {{ [k in keyof z.infer<typeof clientSchema>]: z.infer<typeof clientSchema>[k] | undefined }}
 */
export const clientEnv = {
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
}

export const formatErrors = (
  /** @type {import('zod').ZodFormattedError<Map<string,string>,string>} */
  errors,
) =>
  Object.entries(errors)
    .map(([name, value]) => {
      if (value && "_errors" in value) return `${name}: ${value._errors.join(", ")}\n`
    })
    .filter(Boolean)
