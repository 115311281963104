import { Switch } from "@headlessui/react"
import cn from "classnames"
import React, { FC, useEffect, useState } from "react"

import Label from "./Label"

interface ToggleProps {
  name: string
  label: string
  onChange: (value: boolean) => void
  value: boolean
  description?: string
  small?: boolean
}

const Toggle = React.forwardRef<HTMLButtonElement, ToggleProps>(({ name, label, description, onChange, value, small }: ToggleProps, ref) => (
  <Switch.Group as="div" className="flex items-center justify-between">
    <span className="flex flex-grow flex-col">
      <Switch.Label passive className={"mr-2"}>
        <Label htmlFor={name} text={label} />
      </Switch.Label>
      {description && (
        <Switch.Description as="span" className="text-xs sm:text-sm text-brand-dark opacity-70">
          {description}
        </Switch.Description>
      )}
    </span>
    <Switch
      ref={ref}
      checked={value}
      onChange={onChange}
      className={cn(
        value ? "bg-brand-dark" : "bg-dark-grey bg-opacity-50",
        small ? "h-5 w-9" : "h-6 w-11",
        "relative inline-flex flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-brand-dark focus:ring-offset-2",
      )}
    >
      <span className="sr-only">{label}</span>
      <span
        className={cn(
          value ? (small ? "translate-x-4" : "translate-x-5") : "translate-x-0",
          small ? "h-4 w-4" : "h-5 w-5",
          "pointer-events-none relative inline-block transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
        )}
      >
        <span
          className={cn(value ? "opacity-0 ease-out duration-100" : "opacity-100 ease-in duration-200", "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity")}
          aria-hidden="true"
        >
          <svg className="h-3 w-3 text-dark-grey" fill="none" viewBox="0 0 12 12">
            <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </span>
        <span
          className={cn(value ? "opacity-100 ease-in duration-200" : "opacity-0 ease-out duration-100", "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity")}
          aria-hidden="true"
        >
          <svg className="h-3 w-3 text-brand-dark" fill="currentColor" viewBox="0 0 12 12">
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </Switch>
  </Switch.Group>
))

export default Toggle
