/* eslint-disable max-len */
import { Combobox, Transition } from "@headlessui/react"
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline"
import { Fragment, useEffect, useState } from "react"

import Label from "./Label"

export type ComboBoxOption = {
  id: string
  title: string
  value: string
}

interface Props {
  label: string
  name: string
  errorMessage?: string
  placeholder?: string
  data: ComboBoxOption[]
  onChange: (value: string) => void
  value: string
}

const ComboBox = ({ label, name, data, onChange, value }: Props) => {
  const [selected, setSelected] = useState<string | null>(null)
  const [query, setQuery] = useState("")

  const handleChange = (value: string) => {
    onChange(value)
    setSelected(value)
  }

  useEffect(() => {
    setSelected(value)
  }, [value])

  const filteredData = query === "" ? data : data.filter((d) => d.title.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, "")))

  return (
    <>
      <Combobox value={selected} onChange={handleChange}>
        <div className="relative mt-1">
          <Label htmlFor={name} text={label} />
          <div className="relative w-full">
            <Combobox.Input
              className="shadow-sm focus:ring-brand-dark focus:border-brand-dark block w-full sm:text-base border-dark-grey border-opacity-75 rounded"
              displayValue={(selected: string) => selected}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon className="h-5 w-5 text-brand-dark focus:ring-0" aria-hidden="true" />
            </Combobox.Button>
          </div>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery("")}>
            <Combobox.Options className="absolute mt-1 max-h-60 z-50 w-full overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {filteredData?.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">Nothing found.</div>
              ) : (
                filteredData?.map((data) => (
                  <Combobox.Option
                    key={data.id}
                    className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-light-grey text-brand-dark" : "text-brand-dark"}`}
                    value={data.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>{data.title}</span>
                        {selected ? (
                          <span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-brand-dark" : "text-brand-dark"}`}>
                            <CheckIcon className="h-4 w-4" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </>
  )
}

export default ComboBox
