interface Props {
  htmlFor?: string
  text: string
  description?: string
  infoText?: string
  infoAction?: () => void
}

const Label = ({ htmlFor, text, description, infoText, infoAction }: Props) => {
  return (
    <div className="flex flex-col">
      <div className="flex items-center">
        <label htmlFor={htmlFor} className="block text-xs sm:text-sm font-medium text-brand-dark">
          {text}
        </label>
        {infoText && infoAction && (
          <span onClick={infoAction} className="text-xs font-gray-300 ml-1 hover:underline cursor-pointer">
            {infoText}
          </span>
        )}
      </div>
      {description && <span className="text-xs sm:tex-sm text-brand-dark opacity-70">{description}</span>}
    </div>
  )
}

export default Label
