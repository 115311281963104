import { forwardRef } from "react"
import { ChangeHandler } from "react-hook-form"

import Label from "./Label"

interface Props {
  label: string
  description?: string
  errorMessage?: string
  onChange: ChangeHandler
  onBlur: ChangeHandler
  name: string
  placeholder?: string
  infoText?: string
  password?: boolean
  infoAction?: () => void
}

const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      name,
      label,
      description,
      errorMessage,
      placeholder,
      onChange,
      onBlur,
      infoText,
      password = false,
      infoAction,
    },
    ref,
  ) => {
    return (
      <div className={`relative w-full`}>
        <Label
          htmlFor={name}
          text={label}
          description={description}
          infoText={infoText}
          infoAction={infoAction}
        />
        <input
          type={password ? "password" : "text"}
          name={name}
          id={name}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          className="shadow-sm focus:ring-brand-dark focus:border-brand-dark block w-full sm:text-base border-dark-grey border-opacity-75 rounded"
        />
        <div className="ml-2">
          {errorMessage && <label className="text-xs text-warning-red">{errorMessage}</label>}
        </div>
      </div>
    )
  },
)

export default TextInput
