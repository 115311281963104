import { StringUnitLength } from "luxon"
import React from "react"
import { IMask, IMaskMixin } from "react-imask"

import Label from "./Label"

const IMaskInput = IMaskMixin(({ inputRef, ...props }) => <input ref={inputRef as any} {...props} />)

interface Props {
  name: string
  label?: string
  onChange: (v: string) => void
  defaultValue?: string
  errormessage?: string
  value: string
  mask?: string
}

const MaskedInput = ({ defaultValue, onChange, value, label, mask = "DD.MM.YYYY", ...props }: Props) => {
  const handleAccept = (v: string) => {
    onChange(v)
  }

  const maskPattern = [
    {
      mask: mask,
      lazy: true,
      blocks: {
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31,
        },

        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12,
        },

        YYYY: {
          mask: IMask.MaskedRange,
          from: 1900,
          to: new Date().getFullYear(),
        },
      },
    },
  ]

  return (
    <div className={`relative w-full`}>
      {label && <Label htmlFor={props.name} text={label} />}
      <IMaskInput
        {...props}
        mask={maskPattern}
        onAccept={handleAccept}
        placeholder={mask}
        value={value}
        type="text"
        className="shadow-sm focus:ring-brand-dark focus:border-brand-dark block w-full sm:text-base border-dark-grey border-opacity-75  rounded"
      />
      <div className="ml-2">{props.errormessage && <label className="text-xs text-warning-red">{props.errormessage}</label>}</div>
    </div>
  )
}

export default MaskedInput
