import { useRouter } from "next/router"
import React from "react"

import { Language } from "types/common"

export const useLanguageSwitcher = () => {
  const { locale, pathname, query, isReady, asPath, push } = useRouter()
  const [nextLanguage, setNextLanguage] = React.useState<Language>()

  const computeNextLocale = (locale: string | undefined): Language => {
    return locale === "de" ? "en" : "de"
  }

  React.useEffect(() => {
    const changeTo = locale === "en" ? "de" : "en"

    setNextLanguage(changeTo)
  }, [locale])

  const toggleLanguage = React.useCallback(() => {
    if (locale && isReady) {
      push({ pathname, query }, asPath, { locale: computeNextLocale(locale) })
    }
  }, [push, pathname, asPath, isReady, query, locale])

  return { currentLanguage: locale, nextLanguage, toggleLanguage }
}
