import { useEffect, useState } from "react"

const initialized: HTMLScriptElement[] = []
export function useGoogleMapsApi(apiKey: string, lang: string) {
  const [googleApi, setGoogleApi] = useState()

  const region = lang === "de" ? "DE" : "GB"

  useEffect(() => {
    if ((window as any).google) {
      setGoogleApi((window as any).google)
      return
    }
    const src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&region=${region}&language=${lang}`

    const existingScript = initialized.find((el) => el.src === src)

    const onLoad = () => {
      setGoogleApi((window as any).google)
    }
    if (existingScript) {
      existingScript.addEventListener("load", onLoad)
      return
    }

    const script = document.createElement(`script`)
    script.src = src
    script.async = true
    script.defer = true
    script.addEventListener(`load`, onLoad)
    ;(document.head as any).appendChild(script)
    initialized.push(script)

    return () => {
      script.removeEventListener(`load`, onLoad)
      script.remove()
      initialized.splice(
        initialized.findIndex((el) => el.src === src),
        1,
      )
    }
  }, [apiKey, lang, region])

  return googleApi
}
